"use strict";

import {findIn, findAllIn, on, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

const selectors = {
    base: '.js-scroll-slider--has-counter',
    sliderArea: '.js-scroll-slider__area',
    dots: '.js-scroll-slider__dots-btn',
    counter: '.js-scroll-slider__active-slide',
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let dots = findAllIn(selectors.dots, baseElement);
        let counter = findIn(selectors.counter, baseElement);
        let sliderArea = findIn(selectors.sliderArea, baseElement);

        on('scroll', function(evt) {
            dots.forEach(function (activeDot) {
                if (hasClass('is-active', activeDot)) {
                    let activeId = activeDot.dataset.id;
                    counter.innerHTML = activeId < 10 ? "0" + activeId : activeId;
                }
            });
        }, sliderArea);
    });
}



