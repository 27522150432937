"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findAll, findAllIn, findIn, on, addClass, removeClass} from "@elements/dom-utils";

export function init() {
    console.log("init cca-certification-steps.js");
    onFind('.js-cca-certification-steps', function (stepsWrapper) {
        let steps = findAllIn('.js-cca-certification-steps__step', stepsWrapper);
        let arrowNext = findIn('.js-cca-certification-steps__arrow-next', stepsWrapper);
        let arrowPrev = findIn('.js-cca-certification-steps__arrow-prev', stepsWrapper);

        let currentIndex = 0;

        function updateActiveStep(index) {
            steps.forEach((step, i) => {
                if (i === index) {
                    addClass('is-active', step);
                } else {
                    removeClass('is-active', step);
                }
            });

            // Disable/Enable arrows based on the current index
            if (index === 0) {
                arrowPrev.setAttribute('disabled', 'true');
            } else {
                arrowPrev.removeAttribute('disabled');
            }

            if (index === steps.length - 1) {
                arrowNext.setAttribute('disabled', 'true');
            } else {
                arrowNext.removeAttribute('disabled');
            }
        }

        on('click', function () {
            if (currentIndex < steps.length - 1) {
                currentIndex++;
                updateActiveStep(currentIndex);
            }
        }, arrowNext);

        on('click', function () {
            if (currentIndex > 0) {
                currentIndex--;
                updateActiveStep(currentIndex);
            }
        }, arrowPrev);

        // Initialize the first step as active
        updateActiveStep(currentIndex);
    });
}